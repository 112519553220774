import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";
import logo from "../assets/img/SR-favicon.png";

export default function NavBar({setAnimateContact}) {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

// Contact Click
  function handleContactClick() {
    setAnimateContact(true);
    setTimeout(() => {
      setAnimateContact(false);
    }, 2000); 
    updateActiveLink("contact")
  }

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function updateActiveLink(value) {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img style={{ height: "50px" }} src={logo} />
          </Navbar.Brand>
            <Nav className="d-flex flex-row">
              <Nav.Link
                href="#home"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => updateActiveLink("home")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="#portfolio"
                className={
                  activeLink === "portfolio" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => updateActiveLink("portfolio")}
              >
                Portfolio
              </Nav.Link>
              <Nav.Link
                href="#contact"
                className={
                  activeLink === "contact"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => handleContactClick()}
              >
                Contact
              </Nav.Link>
            </Nav>
        </Container>
      </Navbar>
    </Router>
  );
}
