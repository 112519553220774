import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headshot from "../assets/img/pro-image-2.png";
import Skills from "./Skills";
import email from "../assets/img/email3.svg";
import github from "../assets/img/github3.svg";
import linkedIn from "../assets/img/linkedIn3.svg";
import resume from "../assets/img/resume3.svg";
import "animate.css";
import Certs from "./Certs";
import { usePostHog } from 'posthog-js/react'

export default function Banner({ animateContact }) {
  const [windowLarge, setWindowLarge] = useState(false);
  const posthog = usePostHog()

  // updates the aboutMe based on window size
  useEffect(() => {
    function checkWindowSize() {
      const windowWidth = window.innerWidth;
      const isLarge = windowWidth >= 985 ? true : false;
      return isLarge;
    }

    const handleResize = () => {
      setWindowLarge(checkWindowSize());
    };

    // Initial check
    setWindowLarge(checkWindowSize());

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // logo dets:
  const logos = [
    {
      title: "Email",
      img: email,
      link: "stephaniepotterrowe@gmail.com",
    },
    {
      title: "Github",
      img: github,
      link: "https://github.com/stephsteph123",
    },
    {
      title: "Linkedin",
      img: linkedIn,
      link: "https://www.linkedin.com/in/steph-rowe/",
    },
    {
      title: "Resume",
      img: resume,
      // link: "https://resume.creddle.io/resume/4ctntltsvbb",
      link: "https://flowcv.com/resume/u9emostcf8",
    },
  ];

  function onClick(input) {
    if (input === "stephaniepotterrowe@gmail.com") {
      window.location.href =
        "mailto:stephaniepotterrowe@gmail.com?subject=Reaching%20Out";
    } else {
      window.open(input, "_blank");
      posthog?.capture('banner button clicked!')
    }
  }

  return (
    <div className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7} id="contact">
            <h1>Hi! I'm Steph</h1>
            {windowLarge ? (
              <p>
                Front-End React Developer
                <br />
                Over 7 years of IT experience
                <br />
                Always Hungry to learn and grow
                <br />
                Based in Toronto, Canada 🍁
              </p>
            ) : (
              <p>
                Front-End React Developer
                <br />
                7+ Years IT Experience
                <br />
                Always Learning
                <br />
                Based in Toronto, Canada 🍁
                <br />
              </p>
            )}
            <div className="banner-logos-parent">
              <Row xl={2} lg={2} md={2} sm={1} xs={1}>
                <Col className="banner-logos-col">
                  {logos.map((logo) => (
                    <>
                      {animateContact ? (
                        <>
                        <img
                          className="banner-logos-contact animate__animated animate__wobble"
                          src={logo.img}
                          onClick={() => onClick(logo.link)}
                        />
                        </>
                      ) : (
                        <img
                          className="banner-logos animate__animated"
                          src={logo.img}
                          onMouseEnter={(e) =>
                            e.target.classList.add("animate__wobble")
                          }
                          onMouseLeave={(e) =>
                            e.target.classList.remove("animate__wobble")
                          }
                          onClick={() => onClick(logo.link)}
                        />
                      )}
                    </>
                  ))}
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img
              className="banner-headshot"
              src={headshot}
              style={{ border: "3px solid black" }}
            />
          </Col>
          <Skills />
          <Certs/>
        </Row>
      </Container>
    </div>
  );
}
