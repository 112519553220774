import React from "react";
import pmp from "../assets/img/certs/pmp.png";
import lhlCert from "../assets/img/certs/LHL_transcript.pdf";
import lhl from "../assets/img/certs/lighthouselabs.png";
import jor from "../assets/img/certs/JOR.png"
import { Container, Row, Col } from "react-bootstrap";


export default function Certs() {
  function onClickPMP() {
      window.open(
        "https://www.credly.com/badges/2fff61c5-99ad-4053-9dda-a9d90f950d1b/linked_in_profile"
      );
  }

  function onClickLHL(){
    window.open(lhlCert)
  }

  function onClickJOR(){
    window.alert("certificate is currently in progress");
  }
  return (
    <div className="certs">
      <Container style={{ overflow: "hidden" }}>
        <div className="content">
          <div className="row">
            <Col xs={5} md={3} lg={2}>
              <p>Certificates</p>
            </Col>
            <Col xs={12} md={9} lg={7}>
              <div className="logos">
                <Row>
                  <img src={pmp} onClick={onClickPMP} />
                  <img src={lhl} onClick={onClickLHL}/>
                  <img src={jor} onClick={onClickJOR}/>
                </Row>
              </div>
            </Col>
          </div>
        </div>
      </Container>
    </div>
  );
}
