import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import bootstrap from "../assets/img/stack/bootstrap.svg";
import css from "../assets/img/stack/css.svg";
import html from "../assets/img/stack/html.svg";
import js from "../assets/img/stack/js.svg";
import next from "../assets/img/stack/next.svg";
import postregsql from "../assets/img/stack/postregsql.svg";
import react from "../assets/img/stack/react.svg";
import ts from "../assets/img/stack/ts.svg";
import amplify from "../assets/img/stack/amplify.svg";

export default function Skills() {
  return (
    <div className="skills">
      <Container style={{ overflow: "hidden" }}>
        <div className="content">
          <div className="row">
            <Col xs={4} md={2} lg={2}>
              <p>Tech Stack</p>
            </Col>
            <Col xs={12} md={10} lg={7}>
              <div className="logos">
                <Row>
                  <img src={react} />
                  <img src={html} />
                  <img src={css} />
                  <img src={js} />
                  <img src={ts} />
                  <img src={bootstrap} />
                  <img src={amplify} />
                  <img src={next} />
                  <img src={postregsql} />
                </Row>
              </div>
            </Col>
          </div>
        </div>
      </Container>
    </div>
  );
}
