import React from "react";
import { Container } from "react-bootstrap";
import logo from "../assets/img/SR-logo.png";
import github from "../assets/img/github.svg";
import linkedIn from "../assets/img/linkedIn.svg";
import email from "../assets/img/email.svg";
import resume from "../assets/img/resume.svg"

export default function Footer() {
  const logos = [
    {
      title: "Email",
      img: email,
      link: "stephaniepotterrowe@gmail.com",
    },
    {
      title: "Github",
      img: github,
      link: "https://github.com/stephsteph123",
    },
    {
      title: "Linkedin",
      img: linkedIn,
      link: "https://www.linkedin.com/in/steph-rowe/",
    },
    {
      title: "Resume",
      img: resume,
      link: "https://resume.creddle.io/resume/4ctntltsvbb",
    },
  ];

  function onClick(input) {
    if (input === "stephaniepotterrowe@gmail.com") {
      window.location.href =
        "mailto:stephaniepotterrowe@gmail.com?subject=Reaching%20Out";
    } else {
      window.open(input, "_blank");
    }
  }

  return (
    <div className="footer">
        <div className="content">
          <div className="row-1">
            <img src={logo} style={{ height: "25px" }} />
            <div className="symbols">
              {logos.map((logo) => (
                <img src={logo.img} onClick={() => onClick(logo.link)} />
              ))}
            </div>
          </div>
          <div className="row-2">
            <div>Copyright © 2024. All rights are reserved</div>
          </div>
        </div>
    </div>
  );
}
