import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PR from "../assets/img/projects/pinecone-ridge-img.png"
import wordle from "../assets/img/projects/wordle.png"
import ProjectCard from "./ProjectCard";
import circle from "../assets/img/circle.svg";
import triangle1 from "../assets/img/caret-left.svg"
import triangle2 from "../assets/img/caret-right.svg"

export default function Portfolio() {
  const projects = [
    {
      title: "Pinecone Ridge",
      description: "Pinecone Ridge is a fictitious golf course that required a website revamp. This project uses MUI for the booking component, Bootstrap and CSS styling for all components, and of course React. The next enhancement will be connecting the booking component to Pocketbase.",
      imgUrl: PR,
      github:"https://github.com/stephsteph123/pinecone-ridge"
    },
    {
      title: "Wordle Clone",
      description: "The name says it all, this is a mini Wordle. This project is based off best practices from the Joy of React course. It demonstrates effective use of the core react fundamentals, state, and hooks.",
      imgUrl: wordle,
      github:"https://github.com/stephsteph123/project-wordle"
    }
  ];

  return (
    <section className="projects" id="portfolio">
      <Container className="d-flex justify-content-center text-center">
        <Col lg={8} md={10} sm={12}>
          <div className="content">
            <div className="title-parent">
              <img src={triangle2} className="title-symbol-1" />
              <img src={circle} className="title-symbol-2" />
              <h1>Portfolio</h1>
              <img src={circle} className="title-symbol-3" />
              <img src={triangle1} className="title-symbol-4" />
            </div>
            <Row className="d-flex justify-content-center">
              {projects.map((project, index) => {
                return (
                  <>
                    <ProjectCard key={index} {...project} />
                  </>
                );
              })}
            </Row>
          </div>
        </Col>
      </Container>
    </section>
  );
}
