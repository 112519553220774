import React, { useState } from "react";
import { Col } from "react-bootstrap";
import plus from "../assets/img/plus.svg";
import Details from "./Details";

export default function ProjectCard({ title, imgUrl, description, github }) {
  const [showDetails, setShowDetails] = useState(false);

  function onClick() {
    if (title === "Pinecone Ridge")
      window.open("http://www.project.stephrowe.ca");
    if (title === "Wordle Clone") {
      window.open("http://www.project2.stephrowe.ca");
    }
  }

  return (
    <Col lg={5} md={6}>
      <div className="boarder-box">
        <img src={imgUrl} onClick={onClick} />
        <div className="box-content">
          <h4>{title}</h4>
          <img src={plus} onClick={onClick} />
        </div>
      </div>
      <button
        type="button"
        className="project-card-btn"
        onClick={() => setShowDetails(true)}
      >
        {title} Details
      </button>
      {showDetails && (
        <div className="project-card-overlay">
          <Details
            title={title}
            imgUrl={imgUrl}
            description={description}
            github={github}
            onClick={() => setShowDetails(false)}
          />
        </div>
      )}
    </Col>
  );
}
