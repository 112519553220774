import React from "react";
import { Row, Col } from "react-bootstrap";
import x from "../assets/img/x-square-fill.svg"

export default function Details({
  title,
  imgUrl,
  description,
  github,
  onClick,
}) {
  function onGithubClick() {
    window.open(github, "_blank");
  }

  return (
    <div class="details card">
      <Col className="d-flex justify-content-end">
        <img src={x} className="details-btn-x" onClick={onClick}/>
      </Col>
      <img src={imgUrl} class="details-card-img-top" />
      <div class="card-body">
        <p class="confirmation-card-text">
          <h5 className="d-flex justify-content-center">{title}</h5>
          <Row>
            <Col>
              <div className="details-subtitle">{description}</div>
              <hr></hr>
              <button className="details-btn" onClick={onGithubClick}>
                Github Link
              </button>
            </Col>
          </Row>
        </p>
      </div>
    </div>
  );
}
